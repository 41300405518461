import {AuthService} from './../../services/auth.service';
import {MessageModel} from './../../model/message-model';
import {Injectable} from '@angular/core';
import {AngularFireDatabase, AngularFireList} from '@angular/fire/database';
import {NotificationService} from 'src/app/services/notification.service';
import {NotifyModel} from 'src/app/model/notify-model';
import {first, map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  public static typeDialogs = 'dialogs';  // Диалоги мессенджера

  constructor(
      private db: AngularFireDatabase,
      private authService: AuthService,
      private notivicationService: NotificationService
  ) {
  }

  // индентификатор диалога между двумя пользователями
  getChatID(to: string): string {
    const chatUser = [];
    chatUser.push(to);
    chatUser.push(this.authService.getLogin());

    chatUser.sort((a: string, b: string) => {
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });

    return chatUser[0] + '_' + chatUser[1];
  }

  // Отправка сообщения в диалог
  sendMessage(chatID: string, message: MessageModel): Promise<any> {
    message.user = this.authService.getLogin();
    message.dateCreate = new Date().toISOString();

    this.db.object(ChatService.typeDialogs + '/' + chatID).update({lastMessage: message});
    this.db.object(ChatService.typeDialogs + '/' + chatID).update({id: chatID});

    return this.db.list(ChatService.typeDialogs + '/' + chatID + '/messages').push(message).then(() => {

      const notifyTo = new NotifyModel();
      notifyTo.subject = 'Новое сообщение';
      notifyTo.text = message.text;
      notifyTo.user = this.authService.getLogin();
      notifyTo.url = '/chat/' + notifyTo.user;

      const notifyFrom = new NotifyModel();
      notifyFrom.subject = 'Новое сообщение';
      notifyFrom.text = message.text;
      notifyFrom.user = message.userTo;
      notifyFrom.url = '/chat/' + notifyFrom.user;
      notifyFrom.isRead = true;
      notifyFrom.active = false;

      return Promise.all([
        this.notivicationService
            .updateNotify('messages', message.userTo, notifyTo),
        this.notivicationService
            .updateNotify('messages', this.authService.getLogin(), notifyFrom)
      ]);
    });
  }

  dialogs() {
    return this.db
        .list(`/${ChatService.typeDialogs}`)
        .valueChanges()
        .pipe(
            map(data => {
              console.log(data)
              return data.filter((res: any) => {
                console.log(res)
                return res.id && res.id.indexOf(this.authService.getLogin()) !== -1
              })
            }),
            map(data => {
              return data.map((res: any) => {
                this.getUserName(res.lastMessage.userTo).then((user: any) => {
                  console.log(user)
                  res.user = user
                });
                return res
              })
            })
        )
  }

  async getUserName(id) {
    return this.db.object(`/user/${id}`).valueChanges().pipe(first()).toPromise()
  }

  // Список сообщений в диалоге
  getMessages(chat: string): AngularFireList<MessageModel> {
    return this.db.list(ChatService.typeDialogs + '/' + chat + '/messages');
  }
}
