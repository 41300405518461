import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BrandsService {

  brandName$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor() {
    this.brandName$.next(localStorage.getItem('brand') || '')
  }

  set(brand) {
    this.brandName$.next(brand);
    localStorage.setItem('brand', brand)
  }
}
